define("discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-post-info", ["exports", "@ember/component", "@glimmer/component", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{this.title}}
    class="activity-pub-post-info-modal"
  >
    <:body>
      <div class="activity-pub-post-info-list">
        <span class="activity-pub-state">{{d-icon this.stateIcon}}{{this.stateText}}</span>
        <span class="activity-pub-visibility">{{d-icon this.visibilityIcon}}{{this.visibilityText}}</span>
        {{#unless @model.post.activity_pub_local}}
          <span class="activity-pub-url">
            <a href={{@model.post.activity_pub_url}} target="_blank" rel="noopener noreferrer">
              {{d-icon "external-link-alt"}}
              {{this.urlText}}
            </a>
          </span>
        {{/unless}}
      </div>
    </:body>
  </DModal>
  */
  {
    "id": "AhV7yfcH",
    "block": "[[[8,[39,0],[[24,0,\"activity-pub-post-info-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-post-info-list\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"activity-pub-state\"],[12],[1,[28,[35,1],[[30,0,[\"stateIcon\"]]],null]],[1,[30,0,[\"stateText\"]]],[13],[1,\"\\n      \"],[10,1],[14,0,\"activity-pub-visibility\"],[12],[1,[28,[35,1],[[30,0,[\"visibilityIcon\"]]],null]],[1,[30,0,[\"visibilityText\"]]],[13],[1,\"\\n\"],[41,[51,[30,2,[\"post\",\"activity_pub_local\"]]],[[[1,\"        \"],[10,1],[14,0,\"activity-pub-url\"],[12],[1,\"\\n          \"],[10,3],[15,6,[30,2,[\"post\",\"activity_pub_url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"external-link-alt\"],null]],[1,\"\\n            \"],[1,[30,0,[\"urlText\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"d-icon\",\"unless\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-post-info.hbs",
    "isStrictMode": false
  });
  class ActivityPubPostInfo extends _component2.default {
    get title() {
      return _I18n.default.t("post.discourse_activity_pub.info.title", {
        post_number: this.args.model.post.post_number
      });
    }
    get stateText() {
      return _I18n.default.t(`post.discourse_activity_pub.title.${this.args.model.state}`, {
        time: this.args.model.time.format("h:mm a, MMM D"),
        domain: this.args.model.post.activity_pub_domain,
        object_type: this.args.model.post.activity_pub_object_type
      });
    }
    get stateIcon() {
      return this.args.model.post.activity_pub_local ? "upload" : "download";
    }
    get visibilityText() {
      return _I18n.default.t(`discourse_activity_pub.visibility.description.${this.args.model.post.activity_pub_visibility}`, {
        object_type: this.args.model.post.activity_pub_object_type
      });
    }
    get visibilityIcon() {
      return this.args.model.post.activity_pub_visibility === "public" ? "globe-americas" : "lock";
    }
    get urlText() {
      return _I18n.default.t("post.discourse_activity_pub.info.url", {
        object_type: this.args.model.post.activity_pub_object_type,
        domain: this.args.model.post.activity_pub_domain
      });
    }
  }
  _exports.default = ActivityPubPostInfo;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubPostInfo);
});
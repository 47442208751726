define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-follow", "discourse-common/helpers/i18n", "discourse/components/d-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _activityPubFollow, _i18n, _dButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ActivityPubFollowBtn = _exports.default = (_class = (_class2 = class ActivityPubFollowBtn extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
    }
    showModal() {
      this.modal.show(_activityPubFollow.default, {
        model: this.args.category
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DButton
        @class="activity-pub-follow-btn"
        @action={{this.showModal}}
        @translatedLabel={{i18n "discourse_activity_pub.follow.label"}}
        @translatedTitle={{i18n
          "discourse_activity_pub.follow.title"
          name=@category.name
        }}
      />
    
  */
  {
    "id": "tx6A+O5V",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@class\",\"@action\",\"@translatedLabel\",\"@translatedTitle\"],[\"activity-pub-follow-btn\",[30,0,[\"showModal\"]],[28,[32,1],[\"discourse_activity_pub.follow.label\"],null],[28,[32,1],[\"discourse_activity_pub.follow.title\"],[[\"name\"],[[30,1,[\"name\"]]]]]]],null],[1,\"\\n  \"]],[\"@category\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn.js",
    "scope": () => [_dButton.default, _i18n.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showModal"), _class.prototype)), _class);
});
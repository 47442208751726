define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-image", ["exports", "@glimmer/component", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "discourse-common/lib/avatar-utils", "@ember/template-factory", "@ember/component"], function (_exports, _component, _activityPubUtilities, _avatarUtils, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ActivityPubActorImage extends _component.default {
    get size() {
      return (0, _avatarUtils.translateSize)(this.args.size);
    }
    get url() {
      return this.args.actor.icon_url || "/images/avatar.png";
    }
    get title() {
      return (0, _activityPubUtilities.buildHandle)({
        actor: this.args.actor
      });
    }
  }
  _exports.default = ActivityPubActorImage;
  _class = ActivityPubActorImage;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
          <img
              loading='lazy'
              alt=''
              width={{this.size}}
              height={{this.size}}
              src={{this.url}}
              title={{this.title}} />
      
  */
  {
    "id": "zTWi8ygL",
    "block": "[[[1,\"\\n        \"],[10,\"img\"],[14,\"loading\",\"lazy\"],[14,\"alt\",\"\"],[15,\"width\",[30,0,[\"size\"]]],[15,\"height\",[30,0,[\"size\"]]],[15,\"src\",[30,0,[\"url\"]]],[15,\"title\",[30,0,[\"title\"]]],[12],[13],[1,\"\\n    \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-image.js",
    "isStrictMode": true
  }), _class);
});
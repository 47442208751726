define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-category-followers", ["exports", "discourse/routes/discourse", "discourse/models/category", "@ember/array", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-followers"], function (_exports, _discourse, _category, _array, _activityPubFollowers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    queryParams: {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    },
    model(params) {
      const category = _category.default.findById(params.category_id);
      return _activityPubFollowers.default.load(category, params);
    },
    setupController(controller, model) {
      controller.setProperties({
        model: _activityPubFollowers.default.create({
          category: model.category,
          followers: (0, _array.A)(model.followers || []),
          loadMoreUrl: model.meta?.load_more_url,
          total: model.meta?.total
        })
      });
    }
  });
});
define("discourse/plugins/discourse-activity-pub/discourse/connectors/category-custom-settings/activity-pub-category-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const customFieldDefaults = {
    activity_pub_default_visibility: "public",
    activity_pub_post_object_type: "Note",
    activity_pub_publication_type: "first_post"
  };
  var _default = _exports.default = {
    setupComponent(attrs) {
      Object.keys(customFieldDefaults).forEach(key => {
        if (attrs.category.custom_fields[key] === undefined) {
          attrs.category.custom_fields[key] = customFieldDefaults[key];
        }
      });
    }
  };
});
define("discourse/plugins/discourse-activity-pub/discourse/connectors/composer-action-after/activity-pub-category-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function showStatus(model) {
    return model.get("category.activity_pub_ready") && model.get("action") === "createTopic";
  }
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.site.activity_pub_enabled;
    },
    setupComponent(attrs, component) {
      component.set("showStatus", showStatus(attrs.model));
      attrs.model.addObserver("category", () => {
        if (this._state === "destroying") {
          return;
        }
        component.set("showStatus", showStatus(attrs.model));
      });
    }
  };
});
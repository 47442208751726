define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-category-banner", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", "discourse-common/helpers/i18n", "discourse-i18n", "discourse-common/helpers/d-icon", "float-kit/components/d-tooltip", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _activityPubHandle, _activityPubFollowBtn, _i18n, _discourseI18n, _dIcon, _dTooltip, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ActivityPubCategoryBanner = _exports.default = (_class = (_class2 = class ActivityPubCategoryBanner extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get bannerDescription() {
      const visibility1 = this.args.category.activity_pub_default_visibility;
      const publicationType1 = this.args.category.activity_pub_publication_type;
      return _discourseI18n.default.t(`discourse_activity_pub.banner.${visibility1}_${publicationType1}`);
    }
    get bannerText() {
      const key1 = this.site.mobileView ? 'mobile_text' : 'text';
      return _discourseI18n.default.t(`discourse_activity_pub.banner.${key1}`, {
        category_name: this.args.category.name
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="activity-pub-category-banner">
        {{#if @category}}
          <div class="activity-pub-category-banner-left activity-pub-category-banner-side">
            <DTooltip
              @icon="discourse-activity-pub"
              @content={{this.bannerDescription}}
            />
            <span class="activity-pub-category-banner-text">
              {{this.bannerText}}
            </span>
          </div>
          <div class="activity-pub-category-banner-right activity-pub-category-banner-side">
            {{#unless this.site.mobileView}}
              <ActivityPubHandle @model={{@category}} />
            {{/unless}}
            <ActivityPubFollowBtn @category={{@category}} />
          </div>
        {{/if}}
      </div>
    
  */
  {
    "id": "zRJfDNKC",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-category-banner\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,0],[14,0,\"activity-pub-category-banner-left activity-pub-category-banner-side\"],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@icon\",\"@content\"],[\"discourse-activity-pub\",[30,0,[\"bannerDescription\"]]]],null],[1,\"\\n          \"],[10,1],[14,0,\"activity-pub-category-banner-text\"],[12],[1,\"\\n            \"],[1,[30,0,[\"bannerText\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"activity-pub-category-banner-right activity-pub-category-banner-side\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"site\",\"mobileView\"]]],[[[1,\"            \"],[8,[32,1],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[8,[32,2],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@category\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-category-banner.js",
    "scope": () => [_dTooltip.default, _activityPubHandle.default, _activityPubFollowBtn.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});
define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-status", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.classes}} title={{this.translatedTitle}} role="button">
    {{d-icon "discourse-activity-pub"}}
    <span class="label">{{this.translatedLabel}}</span>
  </div>
  
  */
  {
    "id": "EbPjB5Vt",
    "block": "[[[10,0],[15,0,[30,0,[\"classes\"]]],[15,\"title\",[30,0,[\"translatedTitle\"]]],[14,\"role\",\"button\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"discourse-activity-pub\"],null]],[1,\"\\n  \"],[10,1],[14,0,\"label\"],[12],[1,[30,0,[\"translatedLabel\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-status.hbs",
    "isStrictMode": false
  });
  let ActivityPubStatus = _exports.default = (_class = class ActivityPubStatus extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "messageBus", _descriptor3, this);
      _initializerDefineProperty(this, "forComposer", _descriptor4, this);
      _initializerDefineProperty(this, "category", _descriptor5, this);
      _initializerDefineProperty(this, "ready", _descriptor6, this);
      _initializerDefineProperty(this, "enabled", _descriptor7, this);
      this.forComposer = this.args.modelType === "composer";
      this.category = this.forComposer ? this.args.model.category : this.args.model;
      if (this.category) {
        this.ready = this.category.activity_pub_ready;
        this.enabled = this.category.activity_pub_enabled;
        this.messageBus.subscribe("/activity-pub", this.handleMessage);
        if (this.forComposer && !this.args.model.activity_pub_visibility) {
          this.args.model.activity_pub_visibility = this.category.activity_pub_default_visibility;
        }
      }
    }
    willDestroy() {
      this.messageBus.unsubscribe("/activity-pub", this.handleMessage);
    }
    handleMessage(data) {
      const model = data.model;
      if (model && model.type === this.args.modelType && model.id === this.args.model.id) {
        this.enabled = model.enabled;
        this.ready = model.ready;
      }
    }
    get active() {
      return this.site.activity_pub_enabled && this.enabled && this.ready;
    }
    get translatedTitle() {
      const args = {
        model_type: this.args.modelType
      };
      if (this.active) {
        args.category_name = this.category.name;
        args.delay_minutes = this.siteSettings.activity_pub_delivery_delay_minutes;
      }
      return _I18n.default.t(`discourse_activity_pub.status.title.${this.translatedTitleKey}`, args);
    }
    get translatedTitleKey() {
      if (this.siteSettings.login_required) {
        return "login_required_enabled";
      }
      if (!this.siteSettings.activity_pub_enabled) {
        return "plugin_disabled";
      }
      if (this.args.modelType === "category" && this.args.model.read_restricted) {
        return "category_read_restricted";
      }
      if (!this.enabled) {
        return "model_disabled";
      }
      if (!this.ready) {
        return "model_not_ready";
      }
      if (this.active) {
        return "model_active.first_post";
      } else {
        return "model_not_active";
      }
    }
    get statusKey() {
      return this.active ? "active" : "not_active";
    }
    get classes() {
      let result = `activity-pub-status ${this.statusClass}`;
      if (this.args.onClick) {
        result += " clickable";
      }
      return result;
    }
    get statusClass() {
      return this.active ? "active" : "not-active";
    }
    labelKey(type) {
      let attribute = this.forComposer ? "visibility" : "status";
      let key = this.forComposer ? this.args.model.activity_pub_visibility : this.statusKey;
      return `discourse_activity_pub.${attribute}.${type}.${key}`;
    }
    get translatedLabel() {
      return _I18n.default.t(this.labelKey("label"));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "forComposer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "category", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ready", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "enabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleMessage"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubStatus);
});
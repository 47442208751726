define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse-common/lib/later", "@glimmer/tracking", "discourse/lib/utilities", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _later, _tracking, _utilities, _activityPubUtilities, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="activity-pub-handle">
    <div class="activity-pub-handle-contents">
      <span class="handle">{{handle}}</span>
      {{#if @actor.url}}
        <a
          href={{@actor.url}}
          target="_blank"
          rel="noopener noreferrer"
          class="btn btn-icon no-text"
        >{{d-icon "external-link-alt"}}</a>
      {{/if}}
      {{#if this.copied}}
        <DButton
          @class="btn-hover"
          @icon="copy"
          @label="ip_lookup.copied"
        />
      {{else}}
        <DButton
          @action={{action "copy"}}
          @class="no-text"
          @icon="copy"
        />
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "54WZeWjY",
    "block": "[[[10,0],[14,0,\"activity-pub-handle\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"activity-pub-handle-contents\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"handle\"],[12],[41,[28,[32,0],[\"handle\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"handle\"],[[28,[32,1],[\"handle\"],null]]]]],[[[1,[52,[30,1,[\"handle\"]],[28,[30,1,[\"handle\"]],null,null],[28,[32,2],[[30,0],\"handle\",\"[\\\"The `handle` property path was used in the `discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.handle}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[13],[1,\"\\n\"],[41,[30,2,[\"url\"]],[[[1,\"      \"],[10,3],[15,6,[30,2,[\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"btn btn-icon no-text\"],[12],[1,[28,[35,4],[\"external-link-alt\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"copied\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@class\",\"@icon\",\"@label\"],[\"btn-hover\",\"copy\",\"ip_lookup.copied\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,5],null,[[\"@action\",\"@class\",\"@icon\"],[[28,[37,6],[[30,0],\"copy\"],null],\"no-text\",\"copy\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"maybeHelpers\",\"@actor\"],false,[\"if\",\"handle\",\"let\",\"hash\",\"d-icon\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
  let ActivityPubHandle = _exports.default = (_class = class ActivityPubHandle extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "copied", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
    }
    get handle() {
      const model = this.args.model;
      const actor = this.args.actor;
      const site = this.site;
      return (0, _activityPubUtilities.buildHandle)({
        actor,
        model,
        site
      });
    }
    copy() {
      (0, _utilities.clipboardCopy)(this.handle);
      this.copied = true;
      (0, _later.default)(() => {
        this.copied = false;
      }, 2000);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "copied", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "copy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copy"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubHandle);
});
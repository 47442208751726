define("discourse/plugins/discourse-activity-pub/discourse/connectors/extra-nav-item/activity-pub-category-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.site.activity_pub_enabled;
    }
  };
});
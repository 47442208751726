define("discourse/plugins/discourse-activity-pub/discourse/widgets/post-activity-pub-indicator", ["discourse/widgets/widget", "discourse-common/lib/icon-library", "@ember/string", "I18n", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-post-info"], function (_widget, _iconLibrary, _string, _I18n, _activityPubPostInfo) {
  "use strict";

  (0, _widget.createWidget)("post-activity-pub-indicator", {
    tagName: "div.post-info.activity-pub",
    services: ["modal"],
    title(attrs) {
      return _I18n.default.t(`post.discourse_activity_pub.title.${attrs.state}`, {
        time: attrs.time.format("h:mm a, MMM D"),
        domain: attrs.post.activity_pub_domain,
        object_type: attrs.post.activity_pub_object_type
      });
    },
    buildClasses(attrs) {
      let placeClass = attrs.post.activity_pub_local ? "local" : "remote";
      return [(0, _string.dasherize)(attrs.state), placeClass];
    },
    html() {
      return (0, _iconLibrary.iconNode)("discourse-activity-pub");
    },
    click() {
      this.modal.show(_activityPubPostInfo.default, {
        model: this.attrs
      });
    }
  });
});